import React, { useEffect, useState, lazy } from 'react';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import store from './redux/store';
import Admin from './routes/admin';
import Auth from './routes/auth';
import './static/css/style.css';
import config from './config/config';
import ProtectedRoute from './components/utilities/protectedRoute';
import 'antd/dist/antd.less';
import { guestToken, validateToken } from './service';
import { fetchGuestToken } from './redux/authentication/actionCreator';
import usePageSEO from './hooks/usePageSeo';

const NotFound = lazy(() => import('./container/pages/404'));
const { theme } = config;

function ProviderConfig() {
  const dispatch = useDispatch(); // Hook for dispatching actions
  const { rtl, isLoggedIn, topMenu, mainContent } = useSelector((state) => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      mainContent: state.ChangeLayoutMode.mode,
      isLoggedIn: state.auth.login,
    };
  });
  const GuestContent = useSelector(state => state?.auth)
  console.log("GuestContent", GuestContent)

  const [path, setPath] = useState(window.location.pathname);
  const [token, setToken] = useState(localStorage.getItem('token') || '')
  const [tokenType, setTokenType] = useState(localStorage.getItem('tokenType') || '')

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    return () => (unmounted = true);
  }, [setPath]);

  usePageSEO({
    title: GuestContent?.siteContent?.title,
    description: GuestContent?.siteContent?.description,
    keywords: GuestContent?.siteContent?.keywords,
    favicon: GuestContent?.siteContent?.favicon,
    logo: GuestContent?.siteContent?.logo,
  })

  useEffect(() => {
    const initializeToken = async () => {
      dispatch(fetchGuestToken());
    };
    initializeToken();
  }, [dispatch]);

  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, mainContent }}>
        <Router basename={process.env.PUBLIC_URL}>
          <Routes>
            <Route path="/*" element={<Auth />} />
            <Route path="/admin/*" element={
              <Admin />
            } />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  );
}
function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

export default App;




