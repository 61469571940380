import React, { lazy, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

const Dashboard = lazy(() => import('../../container/dashboard'));
const DemoTwo = lazy(() => import('../../container/dashboard/DemoTwo'));
const DemoThree = lazy(() => import('../../container/dashboard/DemoThree'));
const DemoFour = lazy(() => import('../../container/dashboard/DemoFour'));
const DemoFive = lazy(() => import('../../container/dashboard/DemoFive'));
const DemoSix = lazy(() => import('../../container/dashboard/DemoSix'));
const DemoSeven = lazy(() => import('../../container/dashboard/DemoSeven'));
const DemoEight = lazy(() => import('../../container/dashboard/DemoEight'));
const DemoNine = lazy(() => import('../../container/dashboard/DemoNine'));
const DemoTen = lazy(() => import('../../container/dashboard/DemoTen'));
const NotFound = lazy(() => import('../../container/pages/404'));

function DashboardRoutes() {

  return (
    <Routes >
      {/* <Route index element={<DemoTwo />} /> */}
      <Route index element={<Dashboard />} />
      <Route path="demo-2" element={<DemoTwo />} />
      <Route path="*" element={<NotFound />} />
    </Routes >
  );
}

export default DashboardRoutes;
