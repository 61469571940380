export const baseAuthUrl = 'https://api.yatriyug.in/api';
export const baseUrl = `${process.env.REACT_APP_API_ENDPOINT}/api`;

export const authTokenHeader = () => {
  const headerAuth = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('guest_token')}`,
      'X-Tenant-Id': 'yatriyug.in',
      'Content-Type': 'application/json',
      // 'Origin': 'http://localhost:3000'
    },
  };
  return headerAuth;
};

export const authGuestTokenHeader = () => {
  const headerAuth = {
    headers: {
      'X-Tenant-Id': 'yatriyug.in'
    }
  };
  return headerAuth;
};
