// const GuestContent = useSelector(state => state?.auth)

import { useEffect } from "react"

const usePageSEO = ({ title, description, keywords, favicon, logo }) => {

    useEffect(() => {
        // if (GuestContent?.siteContent) {
        document.title = title
        setMetaTag('name', 'description', description)
        setMetaTag('name', 'keywords', keywords)
        setLinkTag('rel', 'icon', favicon);
        setLinkTag('rel', 'image_src', logo);
        // }
    }, [title, description, keywords, favicon, logo])

    const setMetaTag = (attr, key, content) => {
        if (content) {
            let element = document.querySelector(`meta[${attr}="${key}"]`);
            if (!element) {
                element = document.createElement('meta');
                element.setAttribute(attr, key);
                document.head.appendChild(element);
            }
            element.setAttribute('content', content);
        }
    }
    const setLinkTag = (attr, key, href) => {
        if (href) {
            let element = document.querySelector(`link[${attr}="${key}"]`);
            if (!element) {
                element = document.createElement('link');
                element.setAttribute(attr, key);
                document.head.appendChild(element);
            }
            element.setAttribute('href', href);
        }
    };
}

export default usePageSEO