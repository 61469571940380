import React, { lazy, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import AuthLayout from '../container/profile/authentication/Index';

const Login = lazy(() => import('../container/profile/authentication/overview/SignIn'));
const SignUp = lazy(() => import('../container/profile/authentication/overview/Signup'));
const ForgotPass = lazy(() => import('../container/profile/authentication/overview/ForgotPassword'));
const ResetPass = lazy(() => import('../container/profile/authentication/overview/ResetPassword'));
const loginOtpCheck = lazy(() => import('../container/profile/authentication/overview/LoginOtp'));

const PageWithTitle = ({ title, Component }) => {
  useEffect(() => {
    // document.title = `${title}`;
  }, [title]);

  return <Component />;
}

const AuthRoot = () => {
  const navigate = useNavigate();
  useEffect(() => navigate('/'));
};

const FrontendRoutes = React.memo(() => {
  return (
    <Routes>
      <Route index element={<PageWithTitle Component={Login} />} />
      <Route path="forgotPassword" element={<PageWithTitle title="Forgot Password" Component={ForgotPass} />} />
      <Route path="register" element={<PageWithTitle title="Sign Up" Component={SignUp} />} />
      <Route path="resetPassword" element={<PageWithTitle title="Reset Password" Component={ResetPass} />} />
      <Route path="otpVerification" element={<PageWithTitle title="OTP-Verify" Component={loginOtpCheck} />} />
      <Route path="*" element={<AuthRoot />} />
    </Routes>
  );
});

export default AuthLayout(FrontendRoutes);
