import { postAuthAPI, getSecureAPI, getSecureGuestAPI, postSecureAPI, getValidateTokenAPI } from '../helper';

export const guestToken = async () => {
  const result = await getSecureGuestAPI('/auth/guest-token');
  return result;
};

export const loginauth = async (payload) => {
  const result = await postSecureAPI('/auth/login', payload);
  return result;
};

export const validateToken = async () => {
  const result = await getSecureAPI('/auth/validate-token');
  return result;

};

export const updateUserData = async (payload) => {
  const result = await postAuthAPI('/auth/updateuserdata', payload);
  return result;
};


