import Cookies from 'js-cookie';
import actions from './actions';

const { LOGIN_BEGIN, LOGIN_SUCCESS, LOGIN_ERR, LOGOUT_BEGIN, LOGOUT_SUCCESS, LOGOUT_ERR, FETCH_GUEST_TOKEN_BEGIN,
  FETCH_GUEST_TOKEN_SUCCESS,
  FETCH_GUEST_TOKEN_ERR, } = actions;

const initState = {
  login: Cookies.get('loggedIn'),
  loading: false,
  error: null,
  guestToken: null,
  siteContent: {},
  passwordPolicy: {},
};

/**
 *
 * @todo impure state mutation/explaination
 */
const AuthReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case LOGIN_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };
    case LOGIN_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case LOGOUT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };
    case LOGOUT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    // New cases for fetching guest token and site content
    case FETCH_GUEST_TOKEN_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case FETCH_GUEST_TOKEN_SUCCESS:
      return {
        ...state,
        guestToken: data.token,
        siteContent: data.siteContent,
        passwordPolicy: data.passwordPolicy,
        loading: false,
      };
    case FETCH_GUEST_TOKEN_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};
export default AuthReducer;
