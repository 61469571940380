import Cookies from 'js-cookie';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { guestToken, loginauth, signup } from '../../service';

const { loginBegin, loginSuccess, loginErr, logoutBegin, logoutSuccess, logoutErr, fetchGuestTokenBegin, fetchGuestTokenSuccess, fetchGuestTokenErr } = actions;

const login = (values, callback) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await loginauth(values);
      if (response.data.errors) {
        dispatch(loginErr(response.data.errors));
      } else {
        Cookies.set('access_token', response.data.token, { expires: 5 / 1440 });
        Cookies.set('loggedIn', true, { expires: 5 / 1440 });
        dispatch(loginSuccess(true));
        callback();
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const register = (values) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await signup(values);
      if (response.data.errors) {
        dispatch(loginErr('Registration failed!'));
      } else {
        dispatch(loginSuccess(true));
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const logOut = (callback) => {
  return async (dispatch) => {
    dispatch(logoutBegin());
    try {
      Cookies.remove('loggedIn');
      Cookies.remove('access_token');
      dispatch(logoutSuccess(false));
      callback();
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

// New action to fetch guest token, site content, and password policy
const fetchGuestToken = () => {
  return async (dispatch) => {
    dispatch(fetchGuestTokenBegin());
    try {
      const response = await guestToken();
      if (response.data) {
        localStorage.setItem('guest_token', response.data.token, { expires: 5 / 1440 });
        localStorage.setItem('passwordPolicy', JSON.stringify(response.data.passwordPolicy));
        localStorage.setItem('siteContent', JSON.stringify(response.data.siteContent));

        // Dispatch success action and pass the fetched data
        dispatch(fetchGuestTokenSuccess(response.data));
      } else {
        dispatch(fetchGuestTokenErr('Failed to fetch guest token!'));
      }
    } catch (err) {
      dispatch(fetchGuestTokenErr(err));
    }
  };
};


export { login, logOut, register, fetchGuestToken };
