'use client';
import axios from 'axios';
import { baseUrl, baseAuthUrl, authTokenHeader, authGuestTokenHeader } from '../config/common';

export const getAPI = async (requestUrl) => {
  const requrl = `${baseUrl}/${requestUrl}`;
  const result = await axios.get(requrl);
  return result.data;
};

export const getSecureAPI = async (requestUrl) => {
  const currentAuthTokenHeader = authTokenHeader();
  const requrl = `${baseUrl}${requestUrl}`;
  const result = await axios.get(requrl, currentAuthTokenHeader);
  return result.data;
};

export const getSecureGuestAPI = async (requestUrl) => {
  const currentAuthTokenHeader = authGuestTokenHeader();
  const requrl = `${baseUrl}${requestUrl}`;
  const result = await axios.get(requrl, currentAuthTokenHeader);
  return result.data;
};

export const postAPI = async (requestUrl, payload) => {
  const requrl = `${baseUrl}${requestUrl}`;
  const result = await axios.post(requrl, payload);
  return result.data;
};

export const postSecureAPI = async (requestUrl, payload) => {
  const currentAuthTokenHeader = authTokenHeader();
  console.log('==currentAuthTokenHeader====>', currentAuthTokenHeader);
  const requrl = `${baseAuthUrl}${requestUrl}`;
  const result = await axios.post(requrl, payload, currentAuthTokenHeader);
  return result.data;
};

export const postAuthAPI = async (requestUrl, payload) => {
  const requrl = `${baseAuthUrl}/${requestUrl}`;
  const result = await axios.post(requrl, payload);
  return result.data;
};
export const postUploadAPI = async (requestUrl, payload) => {
  const requrl = `${baseUrl}/${requestUrl}`;
  const result = await axios.post(requrl, payload);
  return result.data;
};
